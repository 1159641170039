'use strict';

angular.module('appApp')
  .directive('primoDatepicker', function () {
    return {
      templateUrl: 'components/primo-datepicker/primo-datepicker.html',
      restrict: 'EA',
      scope: {
        model: '=model'
      },
      link: function () {}
    };
  });
